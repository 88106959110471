&__body {
  display: none;
  width: 97%;
  height: 95%;
  border-radius: 50px;
  background: linear-gradient($bodyColorFieldFrom, $bodyColorFieldTo);
  grid-template-columns: minmax(200px, 1fr) 2fr minmax(200px, 1fr);
  grid-template-rows: repeat(3, auto);
  gap: 20px;
  align-items: center;
  justify-items: center;
  grid-template-areas:
      "trump pc pcName"
      "deck table discard"
      "btn player playerName";

  .card {
    position: absolute;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: center;
    width: 80px;
    height: 120px;
    padding: 5px;
    margin: 5px;
    background: $cardColor;
    border: 2px solid $cardBorder;
    border-radius: 10px;
    z-index: 2;
    opacity: 1;
    transition: .3s;
    font-family: 'Cards Caracter', sans-serif;

    &__top {
      display: flex;
      flex-flow: column;
      align-self: flex-start;
      align-items: center;
    }

    &__bottom {
      display: flex;
      flex-flow: column;
      align-self: flex-end;
      align-items: center;
      transform: rotate(180deg);
    }

    &.hidden {
      opacity: 0;
      transition: .3s;
    }

    &__rankSuit {
      @extend %rankSuitSize;
    }

    .spades {
      @extend %spades;
    }

    .clubs {
      @extend %clubs;
    }

    .diamonds {
      @extend %diamonds;
    }

    .hearts {
      @extend %hearts;
    }

    &__suit {
      @extend %suitSize;
    }

    // рубашки карт
    &__shirt:not(.card__trump) {
      background-color: $cardsShirtColor;
      background-image: url("../assets/svg/suits.svg");
      background-repeat: no-repeat;
    }

    // рубашки карт
    &__shirt:not(.card__trump) > * {
      visibility: hidden;
    }

    &__shirt:not(.deck) > * {
      visibility: hidden;
    }
  }

  .deck {
    grid-area: deck;
    position: relative;
    right: 25px;
    width: 150px;
    height: 150px;

    .card__trump {
      transform: rotate(90deg);
      top: 10px;
      right: -20px;
      z-index: 1;

      &.card__shirt:not(.deck) > * {
        visibility: visible;
      }
    }
  }

  .table {
    position: relative;
    grid-area: table;
    display: flex;
    flex-flow: row;
    justify-content: center;
    width: 100%;

    & .card {
      position: relative;
      background: $cardColor;
      opacity: 1;
      transition: .3s;
    }

    & .card:nth-child(even) {
      position: absolute;
      left: 45%;
      top: 10%;
      box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.4);
      background: $cardColor;
    }

    & > .card.card__shirt > * {
      visibility: visible;
    }
  }

  .discard {
    grid-area: discard;
    display: flex;
    flex-flow: row;
    position: relative;
    left: -25px;
    height: 150px;
    width: 150px;

    .card__trump {
      background-color: $cardsShirtColor;
      background-image: url("../assets/svg/suits.svg");
      background-repeat: no-repeat;
    }

    .card:nth-child(even){
      transform: rotate(random(30) + deg);
    }
  }

  .hands {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    & .card {
      position: static;
    }
  }

  .pcHand {
    grid-area: pc;

    .card__trump {
      background-color: $cardsShirtColor;
      background-image: url("../assets/svg/suits.svg");
      background-repeat: no-repeat;
    }
  }

  .playerHand {
    grid-area: player;

    & > .card.card__shirt > * {
      visibility: visible;
    }

    & > .card {
      background: $cardColor;
      cursor: pointer;
      transition: .3s;

      &.cardsForDefer {
        background: $cardsForDeferColor;
        border-color: $actionBtnDiscardColor;
        transform: translateY(-10px);
      }
    }

    & > .card:hover {
      transform: translateY(-5px);
      background-color: $whiteColor;
      box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.5);
    }
  }

  .actionBtn {
    grid-area: btn;
    position: relative;
    left: 10px;
    visibility: hidden;
    @extend %btn;
    cursor: auto;

    &.active {
      visibility: visible;
      transition: .3s;
    }

    &.grabState {
      background: $actionBtnGrabCardColor;
    }

    &.discardState {
      background: $actionBtnDiscardColor;
    }

    &.turnState {
      background: $actionDefaultColor;
    }
  }

  .trumpOfGame {
    grid-area: trump;
    display: flex;
    width: 100px;
    height: 100px;


    &.clubs {
      @extend %clubs;
    }

    &.diamonds {
      @extend %diamonds;
    }

    &.spades {
      @extend %spades;
    }

    &.hearts {
      @extend %hearts;
    }
  }

  .pcName {
    grid-area: pcName;
    @extend %name;
  }

  .playerName {
    grid-area: playerName;
    @extend %name;
  }
}
