@font-face {
  font-family: 'Cards Caracter';
  src: url('../assets/font/CardsCaracter.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Akzidenz Grotesk';
  src: url('../assets/font/AkzidenzGrotesk.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}
