.startPage {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: $whiteColor;

  &__title {
    font-size: 3rem;
    text-align: center;
    margin-bottom: 100px;
    line-height: 4rem;
  }

  &__newGameBtn {
    @extend %newGameBtn;
  }

  &__decor {
    width: 100%;
    height: 150%;
    background-image: url("../assets/svg/decor.svg");
    background-repeat: no-repeat;
    position: fixed;
    top: -15%;
    left: -15%;
    bottom: 0;
    transform: rotate(20deg);
  }

  &__introCard {
    position: absolute;
    top: 10%;
    right: -55%;
    width: 100%;
    height: 150%;
    background-image: url("../assets/svg/introCard.svg");
    background-repeat: no-repeat;
  }
}